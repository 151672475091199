.section2 .swiper-button-prev {
  color: #000000;
  display: inline-block;
  margin: 40px 5px 0px 90%;
  position: static;
}

.section2 .swiper-button-prev:after {
  font-size: 30px;
}

.section2 .swiper-button-next {
  color: #000000;
  display: inline-block;
  margin: 40px 5px 0px 20px;
  position: static;
}

.section2 .swiper-button-next:after {
  font-size: 30px;
}

.section2 .swiper-pagination-bullet {
  background: #000000;
  }
